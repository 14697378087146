import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Layout from '../layouts'
import { getImage } from 'gatsby-plugin-image'
import Img from 'gatsby-image'

const NotFoundPage = (props) => {


  const data = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "home-page" } }}
        ) {
          edges {
            node {
              frontmatter {
                image {
                  childImageSharp {
                    fluid(maxWidth: 1920, quality: 80) {
                      ...GatsbyImageSharpFluid_withWebp
                      presentationWidth
                    }
                  }
                }
              }
            }
          }
        }
        ...LayoutFragment
      }
    `
  )

  const { markdownRemark: post, footerData, navbarData, bannerData } = data

  const image = getImage(data.allMarkdownRemark.edges[0].node.frontmatter.image.childImageSharp.fluid)


  return (
    <Layout navbarData={navbarData} footerData={footerData} bannerData={bannerData}>
    <div className='imageContainer' style={{ position: 'relative' }}>
      <Img
        fluid={data.allMarkdownRemark.edges[0].node.frontmatter.image.childImageSharp.fluid}
        style={{
          width: '100%',
          height: '100vh',
          position: 'fixed',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          filter: 'brightness(40%)'
        }}
      />
    </div>
      <section className='section'>
        <div className='columns is-vcentered' style={{ marginTop: '10rem' }}>
          <div className='column is-12'>
            <div className='container' style={{ background: 'rgba(0,0,0,0.5)', padding: '20px' }}>
              <h1 className='title has-text-centered has-text-white-ter'>NOT FOUND</h1>

              <h2 className='subtitle has-text-centered'>Diese Seite existiert leider nicht.</h2>
              <div className='columns'>
                <div className='column is-12 has-text-centered'>
                  <Link className='button is-medium is-outlined is-primary' to='/'>zur Hauptseite
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default NotFoundPage

// export const notFoundPageQuery = graphql`
//   query NotFoundPage {
//     allMarkdownRemark {
//       edges {
//         node {
//           frontmatter {
//             image {
//               childImageSharp {
//                 gatsbyImageData(
//                   width: 2048
//                   placeholder: BLURRED
//                   formats: [AUTO, WEBP]
//                 )
//               }
//             }
//           }
//         }
//       }
//     }
//     ...LayoutFragment
//   }
// `
